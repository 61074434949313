<template>
  <b-container class="profile-container">
    <b-overlay :show="isLoading">
      <!-- <router-link class="nav-link" to="/">
      <font-awesome-icon
        class="label-icon"
        icon="arrow-left"
      ></font-awesome-icon
      >Go back
    </router-link> -->
      <b-card>
        <div class="row">
          <!-- Profile side bar -->
          <div class="profile-side col-3">
            <div class="profile-header">
              <!-- Profile picture -->
              <b-avatar
                variant="info"
                :text="userInitials"
                :src="profilePicture"
              ></b-avatar>

              <!-- Name and Email info -->
              <div class="profile-info">
                <h2>{{ user.first_name }}</h2>
                <p class="text-muted">{{ user.email }}</p>
              </div>
            </div>
          </div>

          <!-- Edit Profile -->
          <div class="col-8 profile-main">
            <div class="account-heading">
              <font-awesome-icon
                class="label-icon"
                icon="user"
              ></font-awesome-icon>
              {{ $t("account") }}
            </div>
            <!-- Username field -->
            <!-- <div class="account-field">
            <div class="field-label">
              <label for="username">{{ $t("username") }}</label>
            </div>
            <div class="field-area">
              <input :value="user.username" />
            </div>
          </div> -->
            <div class="account-field">
              <div class="field-label">
                <label for="profile-picture">{{ $t("profilePicture") }}</label>
              </div>
              <div class="field-area">
                <ImageUpload
                  :title="$t('profilePicture')"
                  ref="image"
                  :url="user.profile_image_url"
                  @updateImage="updateImage"
                />
              </div>
            </div>
            <!-- Firstname field -->
            <div class="account-field">
              <div class="field-label">
                <label for="username">{{ $t("firstname") }}</label>
              </div>
              <div class="field-area">
                <b-input v-model="user.first_name" />
              </div>
            </div>
            <!-- Lastname field -->
            <div class="account-field">
              <div class="field-label">
                <label for="username">{{ $t("lastname") }}</label>
              </div>
              <div class="field-area">
                <b-input v-model="user.last_name" />
              </div>
            </div>
            <!-- Submit button -->

            <div class="account-submit">
              <b-alert
                align-v="center"
                :variant="messageVariant"
                :show="saveMessage !== ''"
                >{{ this.saveMessage }}</b-alert
              >
              <b-button
                @click="doSave()"
                :disabled="saveDisabled"
                submit="submit"
                >{{ $t("updateaccount") }}</b-button
              >
            </div>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </b-container>
</template>
<script>
import ImageUpload from "@/components/admin/ImageUpload.vue";

export default {
  name: "BasicProfile",
  components: {
    ImageUpload,
  },
  data() {
    return {
      saveDisabled: true,
      isLoading: false,
      saveMessage: "",
      messageVariant: null,
    };
  },
  watch: {
    user: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.messageVariant = "warning";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  async created() {
    await this.doFetch();
    this.saveDisabled = true;
    this.saveMessage = "";
  },
  computed: {
    userInitials() {
      return `${this.user?.first_name?.charAt(0)}${this.user?.last_name?.charAt(
        0
      )}`;
    },
    profilePicture() {
      return this.user?.profile_image_url;
    },
    isLoggedIn() {
      return this.$store.getters["Auth/isLoggedIn"];
    },
    settings() {
      return this.$store.state.Config.all;
    },
    user() {
      return this.$store.state.Users.currentUser;
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
  },
  methods: {
    updateImage(newUrl) {
      this.user.profile_image_url = newUrl;
    },
    async doFetch() {
      this.isLoading = true;
      await this.$store.dispatch("Users/fetchById", this.currentUser.id);
      this.isLoading = false;
    },
    async doSave() {
      this.isLoading = true;
      this.$refs.image.uploadImage();
      await this.$store.dispatch("Users/update", this.user);
      await this.doFetch();
      this.saveDisabled = true;
      this.saveMessage = "Saved changes!";
      this.messageVariant = "success";
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss">
.label-icon {
  margin-right: 10px;
}
.profile-container {
  /* grid-area: content; */
  flex-grow: 1;
  width: 100%;
  padding-top: 35px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 35px;
}
.profile-side {
  .profile-header {
    text-align: center;
    .profile-picture {
      img {
        max-height: 120px;
        border-radius: 999px;
      }
    }
    .profile-info {
      text-align: center;
      padding-top: 12px;
    }
  }

  ul {
    margin: 0 !important;
    padding: 0 !important;
    list-style-type: none !important;
    list-style: disc;
  }
  li {
    margin-bottom: 1px !important;
    background: #eee;

    a {
      text-decoration: none;
      font-size: 16px !important;
      height: 30px;
      line-height: 20px;
      color: #000000 !important;
      position: relative;
      .account-icon {
      }
      .account-title {
        padding-left: 20px;
        float: left;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

.profile-main {
  float: left;
  width: 70%;
  padding: 0px 0px 0px 1.5em;
  box-sizing: border-box;

  .account-heading {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    margin: 0 !important;
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
  }

  .account-field {
    position: relative;
    padding: 15px 0 0 0;

    .field-label {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin: 0 0 8px 0;

      label {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
      }
    }
    .field-area {
      position: relative;
      word-wrap: break-word;

      input {
        color: #000000;
        border: 1px solid #ddd !important;
        padding: 0 12px !important;
        width: 100%;
        display: block !important;
        height: 40px !important;
        box-sizing: border-box !important;
        box-shadow: none !important;
        margin: 0 !important;
        position: static;
        outline: none !important;
      }
    }
  }
  .account-submit {
    margin: 10px 0px 5px;
    padding-top: 10px;
    font-size: 15px;

    .profile-button {
      font-size: 15px;
      padding: 15px;
      background: none;
      border: 2px solid #fff !important;
    }
  }
}
</style>
